import { Box, HStack, Image, Pressable, Text, VStack } from "native-base";
import { useInView } from "react-intersection-observer";

import { type IGetManyStoresRecord } from "@cannabox/api-types";

import { Heading } from "~root/components/base";
import { useRootNavigation } from "~root/navigation";
import ParterIcon from "./ParterIcon";
import RatingStars from "./RatingStars";

export interface IStoreListItemProps {
  store: IGetManyStoresRecord;
}
export default function StoreListItem({ store }: IStoreListItemProps) {
  const navigation = useRootNavigation();
  const [ref, isInView] = useInView({
    triggerOnce: true,
    rootMargin: "25px",
  });

  return (
    <Pressable
      bg="white"
      shadow="productCardShadow"
      my="1.5"
      ref={ref}
      p="3"
      borderRadius="3xl"
      _pressed={{ bg: store.inventoryCount > 0 ? "secondary.200" : "gray.200" }}
      onPress={() => {
        navigation.navigate("Store", {
          city: store.city,
          urlSlug: store.urlSlug,
        });
      }}
    >
      <HStack space="4">
        {isInView ? (
          <Image
            source={{ uri: store.thumbnail }}
            alt={store.title}
            size="md"
            borderRadius="xl"
            resizeMode="cover"
          />
        ) : (
          <Box size="20" />
        )}

        <VStack flex="1" paddingTop="2">
          <HStack justifyContent="space-between">
            <Heading level={2} fontSize="md" numberOfLines={1}>
              {store.title}
            </Heading>
            {store.inventoryCount > 0 && <ParterIcon color="secondary.800" />}
          </HStack>

          <HStack alignItems="baseline" space="4">
            <HStack alignItems="baseline" space="1">
              <RatingStars numberOfStars={store.rating} />
              <Text fontSize="sm" fontWeight="medium" color="gray.600">
                {store.rating}
              </Text>
            </HStack>
            <Text fontSize="2xs" color="gray.600">{`(${
              store.reviews ?? 0
            } Reviews)`}</Text>
          </HStack>

          <Text mt="1" numberOfLines={1} fontSize="xs" color="gray.500">
            {store.address}
          </Text>
        </VStack>
      </HStack>
    </Pressable>
  );
}

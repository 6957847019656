import { Path } from "react-native-svg";
import { createIcon } from "native-base";

export default createIcon({
  viewBox: "0 0 12 12",
  path: [
    <Path
      key="1"
      d="M4.755.94a1.295 1.295 0 0 1 2.49 0l.52 1.66c.174.561.677.941 1.245.941h1.678c1.268 0 1.796 1.688.77 2.463L10.1 7.03a1.392 1.392 0 0 0-.476 1.522l.519 1.66c.392 1.254-.99 2.297-2.015 1.522L6.77 10.708a1.27 1.27 0 0 0-1.54 0l-1.358 1.026c-1.026.775-2.407-.268-2.015-1.523l.519-1.659A1.392 1.392 0 0 0 1.9 7.03L.542 6.004c-1.026-.775-.499-2.463.77-2.463H2.99c.568 0 1.07-.38 1.246-.94L4.755.94Z"
    />,
  ],
});

import { useMemo } from "react";
import { type IStackProps } from "native-base";

import { type IFlowerDataRecord } from "@cannabox/api-types";

import type { ProductBadgesProps } from "./ProductBadges";
import { supportedFlowerCategories } from "~root/config";
import { type cannaboxTheme } from "~root/services/cannabox-theme";
import ProductBadges from "./ProductBadges";

export interface FlowerBadgesProps {
  flowerData: IFlowerDataRecord;
  lines?: number;
  shadow?: keyof typeof cannaboxTheme.shadows;
  stack?: IStackProps;
}

export default function FlowerBadges({
  flowerData,
  lines,
  shadow,
  stack,
}: FlowerBadgesProps) {
  const productBadges = useMemo(() => {
    const badges: ProductBadgesProps["productBadges"] = [
      {
        colorScheme: "thc",
        children: `${flowerData.thcPercentage}% THC`,
      },
    ];

    if (flowerData.cbdPercentage > 0) {
      badges.push({
        colorScheme: "cbd",
        children: `${flowerData.cbdPercentage}% CBD`,
      });
    }

    badges.push({
      colorScheme: flowerData.category,
      children: supportedFlowerCategories[flowerData.category],
    });

    return badges;
  }, [flowerData]);

  return (
    <ProductBadges
      lines={lines}
      stack={stack}
      shadow={shadow}
      productBadges={productBadges}
    />
  );
}

import { Image, Pressable, Text, VStack, ZStack } from "native-base";
import { useInView } from "react-intersection-observer";

import { type IStoreInventoryItemRecord } from "@cannabox/api-types";

import { Heading } from "~root/components/base";
import { formatCurrency } from "~root/services/intl";
import {
  EdibleBadges,
  FlowerBadges,
  PrerollBadges,
  TinctureBadges,
} from "./badges";

export interface InventoryItemCardProps {
  inventoryItem: IStoreInventoryItemRecord;
  onPress?: () => void;
}

export default function InventoryItemCard({
  inventoryItem,
  onPress = () => undefined,
}: InventoryItemCardProps) {
  const [ref, isInView] = useInView({
    triggerOnce: true,
    rootMargin: "25px",
  });

  return (
    <Pressable
      ref={ref}
      bg="white"
      shadow="productCardShadow"
      width="48"
      padding="3"
      borderRadius="3xl"
      _pressed={{
        bg: "gray.200",
      }}
      onPress={onPress}
    >
      <VStack space="2">
        <ZStack width="full" height="32">
          {isInView && (
            <Image
              source={{
                uri:
                  inventoryItem.mainPhoto["360p"] ??
                  inventoryItem.mainPhoto.thumbnail,
              }}
              size="32"
              resizeMode="cover"
              alt={inventoryItem.title}
            />
          )}

          {inventoryItem.category === "flowers" && inventoryItem.flowerData && (
            <FlowerBadges flowerData={inventoryItem.flowerData} lines={2} />
          )}

          {inventoryItem.category === "edibles" && inventoryItem.edibleData && (
            <EdibleBadges edibleData={inventoryItem.edibleData} lines={2} />
          )}

          {inventoryItem.category === "prerolls" &&
            inventoryItem.prerollData && (
              <PrerollBadges
                prerollData={inventoryItem.prerollData}
                lines={2}
              />
            )}

          {inventoryItem.category === "tinctures" &&
            inventoryItem.tinctureData && (
              <TinctureBadges
                tinctureData={inventoryItem.tinctureData}
                lines={2}
              />
            )}
        </ZStack>

        <VStack space="1">
          <VStack>
            <Heading level={3} fontSize="md" numberOfLines={1}>
              {inventoryItem.title}
            </Heading>
            <Text fontSize="xs" color="muted.400">
              {inventoryItem.brandTitle}
            </Text>
          </VStack>

          <Text fontSize="md" fontWeight="medium">
            {formatCurrency(inventoryItem.price, "short")}
          </Text>
        </VStack>
      </VStack>
    </Pressable>
  );
}

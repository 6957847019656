import { Box, HStack } from "native-base";

import StarIcon from "~root/components/icons/StarIcon";

export interface IRatingStarsProps {
  color?: string;
  numberOfStars?: number;
}
export default function RatingStars({
  color = "#FFCB5C",
  numberOfStars = 0,
}: IRatingStarsProps) {
  const roundedNumberOfStars = Math.round(numberOfStars);

  return (
    <HStack justifyContent="flex-start" mb="1">
      {Array(roundedNumberOfStars)
        .fill(0)
        .map((_, i) => (
          <Box key={i} mr="1">
            <StarIcon size="2.5" color={color} />
          </Box>
        ))}
    </HStack>
  );
}

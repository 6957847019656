export interface SeoLocalBusiness {
  name: string;
  description: string;
  address: string;
  telephone: string;
  url: string;
  image: string;
  geo: {
    latitude: number;
    longitude: number;
  };
}

export const createLocalBusiness = (business: SeoLocalBusiness) => ({
  "@context": "https://schema.org",
  "@type": "Store",
  name: business.name,
  description: business.description,
  address: business.address,
  url: business.url,
  image: business.image,
  currenciesAccepted: "THB",
  geo: {
    "@type": "GeoCoordinates",
    latitude: business.geo.latitude,
    longitude: business.geo.longitude,
  },
  areaServed: {
    "@type": "GeoCircle",
    geoMidpoint: {
      "@type": "GeoCoordinates",
      latitude: business.geo.latitude,
      longitude: business.geo.longitude,
    },
    geoRadius: "20 km",
  },
});

import Seo from "~root/components/Seo";
import { appUrl, supportedCities } from "~root/config";

export interface StoresSeoProps {
  city?: string;
}

export function StoresSeo({ city }: StoresSeoProps) {
  const placeTitle = city ? supportedCities[city] : "Thailand";

  return (
    <Seo
      title={
        city
          ? `${placeTitle}'s Curated Dispensaries | Weed Delivery - Cannabox`
          : "Weed Delivery from Curated Dispensaries Near You - Cannabox"
      }
      description={
        city
          ? `Connect with quality-ensured curated cannabis products near you from local dispensaries and brands in ${placeTitle} for really fast weed delivery averaging 35 min✓`
          : "We securely enable consumers to get quality-ensured curated cannabis products from local dispensaries and brands for really fast weed delivery averaging 35 min✓"
      }
      breadcrumbs={
        city ? [{ title: placeTitle, url: `${appUrl}/${city}` }] : undefined
      }
    />
  );
}

import { useMemo } from "react";
import { type IStackProps } from "native-base";

import { type IEdibleDataRecord } from "@cannabox/api-types";

import type { ProductBadgesProps } from "./ProductBadges";
import { type cannaboxTheme } from "~root/services/cannabox-theme";
import ProductBadges from "./ProductBadges";

export interface EdibleBadgesProps {
  edibleData: IEdibleDataRecord;
  lines?: number;
  shadow?: keyof typeof cannaboxTheme.shadows;
  stack?: IStackProps;
}

export default function EdibleBadges({
  edibleData,
  shadow,
  lines,
  stack,
}: EdibleBadgesProps) {
  const productBadges = useMemo(() => {
    const badges: ProductBadgesProps["productBadges"] = [
      {
        colorScheme: "thc",
        children: `${edibleData.thcMg}mg THC`,
      },
    ];

    if (edibleData.cbdMg > 0) {
      badges.push({
        colorScheme: "cbd",
        children: `${edibleData.cbdMg}mg CBD`,
      });
    }

    if (edibleData.pcsPerPackage > 1) {
      badges.push({
        colorScheme: "pcs",
        children: `${edibleData.pcsPerPackage}pcs`,
      });
    }

    return badges;
  }, [edibleData]);

  return (
    <ProductBadges
      lines={lines}
      stack={stack}
      shadow={shadow}
      productBadges={productBadges}
    />
  );
}

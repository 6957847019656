import { useEffect } from "react";
import { useRoute } from "@react-navigation/native";

import type { StoresRouteProp } from "~root/navigation";
import { supportedCities } from "~root/config";
import { useRootNavigation } from "~root/navigation";

export const useStoresListViewParams = () => {
  const route = useRoute<StoresRouteProp>();
  const { navigate } = useRootNavigation();

  const city = route.params?.city;

  useEffect(() => {
    if (city !== undefined && supportedCities[city] === undefined) {
      navigate("Stores");
    }
  }, [navigate, city]);

  return { city };
};

import { VStack } from "native-base";

import type { UseRecommendedProductsQueryOptions } from "~root/services/api";
import { Heading } from "~root/components/base";
import HScrollInventoryItems from "~root/components/product/HScrollInventoryItems";
import { useRecommendedProductsQuery } from "~root/services/api";
import { useLocation } from "~root/services/location";

export interface RecommendedProductsProps {
  city?: string;
}

export default function RecommendedProducts({
  city,
}: RecommendedProductsProps) {
  const location = useLocation();

  const params: UseRecommendedProductsQueryOptions = {};
  if (location) params.location = location;
  else if (city) params.city = city;

  const { data } = useRecommendedProductsQuery(params);

  if (!data || data.totalRecords === 0 || data.records.length === 0)
    return null;

  let title = "Recommended around Thailand";

  if (city) {
    title = `Recommended around ${city}`;
  }

  if (location) {
    title = `Recommended around you`;
  }

  return (
    <VStack>
      <Heading level={2} fontSize="lg" mt="3">
        {title}
      </Heading>
      <HScrollInventoryItems
        inventoryItems={data.records.map((record) => ({
          city: record.city,
          storeUrlSlug: record.storeUrlSlug,
          ...record.inventoryItem,
        }))}
      />
    </VStack>
  );
}

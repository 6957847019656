import { useRoute } from "@react-navigation/native";
import { Helmet } from "react-helmet";

import type { SeoBreadcrumb } from "./createBreadcrumbs";
import type { SeoLocalBusiness } from "./createLocalBusiness";
import type { SeoProduct } from "./createProduct";
import { appUrl, cannaboxEnv, isStandAloneStore } from "~root/config";
import { createBreadcrumbs } from "./createBreadcrumbs";
import { createLocalBusiness } from "./createLocalBusiness";
import { createProduct } from "./createProduct";

export interface ISeoProps {
  title?: string;
  description?: string;
  image?: string;
  breadcrumbs?: SeoBreadcrumb[];
  localBusiness?: SeoLocalBusiness;
  product?: SeoProduct;
}

const stringifyObject = (obj: object) => {
  if (cannaboxEnv !== "production") {
    return JSON.stringify(obj, null, 2);
  }

  return JSON.stringify(obj);
};

export default function Seo({
  title = "Weed Delivery from Curated Dispensaries Near You - Cannabox",
  description = "We securely enable consumers to get quality-ensured curated cannabis products from local dispensaries and brands for really fast weed delivery averaging 35 min✓",
  image,
  breadcrumbs,
  localBusiness,
  product,
}: ISeoProps) {
  const { path } = useRoute();

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      {(cannaboxEnv !== "production" || isStandAloneStore) && (
        <meta name="robots" content="noindex" />
      )}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Cannabox Inc." />
      {path && <meta property="og:url" content={`${appUrl}${path}`} />}
      {image && <meta property="og:image" content={image} />}
      {image && <meta property="og:image:alt" content={description} />}

      {image ? (
        <meta name="twitter:card" content="summary_large_image" />
      ) : (
        <meta name="twitter:card" content="summary" />
      )}
      <meta name="twitter:site" content="@cannaboxthai" />

      {breadcrumbs && (
        <script type="application/ld+json">
          {stringifyObject(createBreadcrumbs(breadcrumbs))}
        </script>
      )}

      {localBusiness && (
        <script type="application/ld+json">
          {stringifyObject(createLocalBusiness(localBusiness))}
        </script>
      )}

      {product && (
        <script type="application/ld+json">
          {stringifyObject(createProduct(product))}
        </script>
      )}
    </Helmet>
  );
}

import type { IStackProps } from "native-base";
import { useMemo } from "react";
import { VStack } from "native-base";

import type { ProductBadgeProps } from "./ProductBadge";
import { type cannaboxTheme } from "~root/services/cannabox-theme";
import ProductBadge from "./ProductBadge";

export interface ProductBadgesProps {
  productBadges?: ProductBadgeProps[];
  lines?: number;
  shadow?: keyof typeof cannaboxTheme.shadows;
  stack?: IStackProps;
}

export default function ProductBadges({
  productBadges = [],
  lines,
  shadow,
  stack = {
    space: "2",
    alignItems: "flex-end",
    marginTop: "2",
  },
}: ProductBadgesProps) {
  const productBadgesToShow = useMemo(
    () =>
      productBadges.filter((_, index) => lines === undefined || lines > index),
    [productBadges, lines],
  );

  return (
    <VStack {...stack}>
      {productBadgesToShow.map((productBadge, index) => (
        <ProductBadge key={index} shadow={shadow} {...productBadge} />
      ))}
    </VStack>
  );
}

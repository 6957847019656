import { type ICurrencyRecord } from "@cannabox/api-types";

export interface SeoProduct {
  name: string;
  brand: string;
  image: string;
  description: string;
  price: ICurrencyRecord;
  url: string;
  category: string;
  isInStock: boolean;
  geo: {
    latitude: number;
    longitude: number;
  };
}

export const createProduct = (item: SeoProduct) => ({
  "@context": "https://schema.org",
  "@type": "Product",
  name: item.name,
  brand: item.brand,
  url: item.url,
  image: item.image,
  description: item.description,
  offers: {
    "@type": "Offer",
    url: item.url,
    image: item.image,
    price: (item.price.currencyValue / 100).toFixed(2),
    priceCurrency: item.price.currencyCode,
    category: `Cannabis ${item.category}`,
    availability: item.isInStock
      ? "https://schema.org/InStock"
      : "https://schema.org/OutOfStock",
    availableDeliveryMethod:
      "http://purl.org/goodrelations/v1#DeliveryModeOwnFleet",
    areaServed: {
      "@type": "GeoCircle",
      geoMidpoint: {
        "@type": "GeoCoordinates",
        latitude: item.geo.latitude,
        longitude: item.geo.longitude,
      },
      geoRadius: "20 km",
    },
    shippingDetails: {
      "@type": "OfferShippingDetails",
      shippingRate: {
        "@type": "MonetaryAmount",
        currency: item.price.currencyCode,
        value: 0.0,
      },
      deliveryTime: {
        "@type": "ShippingDeliveryTime",
        handlingTime: {
          "@type": "QuantitativeValue",
          minValue: 2,
          maxValue: 10,
          unitCode: "min",
          unitText: "minutes",
        },
        transitTime: {
          "@type": "QuantitativeValue",
          minValue: 10,
          maxValue: 50,
          unitCode: "min",
          unitText: "minutes",
        },
      },
    },
  },
});

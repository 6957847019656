import { useCallback } from "react";
import { Platform } from "react-native";
import { FlatList, Skeleton, Text } from "native-base";

import { useStoresRoute } from "~root/navigation";
import { useInfiniteStoresQuery } from "~root/services/api";
import { useLocation } from "~root/services/location";
import { useKeyExtractor, useRenderItem, useStoresListData } from "./hooks";

export default function StoresList() {
  const { params } = useStoresRoute();
  const { city } = params ?? {};

  const location = useLocation();

  const {
    records,
    fetchNextPage,
    refetch,
    isRefetching,
    isInitialLoading,
    isFetching,
  } = useInfiniteStoresQuery(location !== undefined ? { location } : { city });

  const isLoadingFirstItems = isInitialLoading || isRefetching;

  const onEndReached = useCallback(() => {
    fetchNextPage().catch(console.error);
  }, [fetchNextPage]);

  const onRefresh = useCallback(() => {
    refetch().catch(console.error);
  }, [refetch]);

  const data = useStoresListData(records);
  const keyExtractor = useKeyExtractor();
  const renderItem = useRenderItem(city);

  return (
    <FlatList
      px="3"
      pb="32"
      data={data}
      refreshing={false}
      onRefresh={Platform.OS !== "web" ? onRefresh : undefined}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.5}
      ListEmptyComponent={
        <>
          {!isLoadingFirstItems && (
            <Text color="gray.500" textAlign="center" mt={5}>
              No stores found
            </Text>
          )}
        </>
      }
      ListFooterComponent={
        <>
          {(isLoadingFirstItems || isFetching) &&
            Array.from({ length: 10 }).map((_, i) => (
              <Skeleton key={i} my="1.5" borderRadius="3xl" height={104} />
            ))}
        </>
      }
    />
  );
}

import { useMemo } from "react";
import { type IStackProps } from "native-base";

import { type IPrerollDataRecord } from "@cannabox/api-types";

import type { ProductBadgesProps } from "./ProductBadges";
import { supportedPrerollCategories } from "~root/config";
import { type cannaboxTheme } from "~root/services/cannabox-theme";
import ProductBadges from "./ProductBadges";

export interface PrerollBadgesProps {
  prerollData: IPrerollDataRecord;
  lines?: number;
  shadow?: keyof typeof cannaboxTheme.shadows;
  stack?: IStackProps;
}

export default function PrerollBadges({
  prerollData,
  shadow,
  lines,
  stack,
}: PrerollBadgesProps) {
  const productBadges = useMemo(() => {
    const badges: ProductBadgesProps["productBadges"] = [
      {
        colorScheme: "thc",
        children: `${prerollData.thcPercentage}% THC`,
      },
    ];

    if (prerollData.pcsPerPackage > 1) {
      badges.push({
        colorScheme: "pcs",
        children: `${prerollData.pcsPerPackage}pcs`,
      });
    }

    if (prerollData.mgPerItem > 0) {
      badges.push({
        colorScheme: "grams",
        children: `${prerollData.mgPerItem / 1000}g`,
      });
    }

    badges.push({
      colorScheme: prerollData.category,
      children: supportedPrerollCategories[prerollData.category],
    });

    return badges;
  }, [prerollData]);

  return (
    <ProductBadges
      lines={lines}
      stack={stack}
      shadow={shadow}
      productBadges={productBadges}
    />
  );
}

import { type ReactNode } from "react";
import { Badge } from "native-base";

import { type cannaboxTheme } from "~root/services/cannabox-theme";

export interface ProductBadgeProps {
  colorScheme: "thc" | "cbd" | "sativa" | "indica" | "hybrid" | "pcs" | "grams";
  shadow?: keyof typeof cannaboxTheme.shadows;
  children?: ReactNode;
}

const colorSchemeMap: Record<string, keyof typeof cannaboxTheme.colors> = {
  thc: "light",
  cbd: "lime",
  sativa: "green",
  indica: "indigo",
  hybrid: "gray",
  pcs: "gray",
  mg: "orange",
};

export default function ProductBadge({
  colorScheme,
  shadow,
  children,
}: ProductBadgeProps) {
  return (
    <Badge
      colorScheme={colorSchemeMap[colorScheme]}
      variant="vibrant"
      shadow={shadow}
    >
      {children}
    </Badge>
  );
}

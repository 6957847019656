import { type ComponentProps } from "react";
import { Platform } from "react-native";
import { H1, H2, H3, H4, H5, H6 } from "@expo/html-elements";
import { Factory, Heading as NbHeading, useTheme } from "native-base";

const headingMap = {
  1: H1,
  2: H2,
  3: H3,
  4: H4,
  5: H5,
  6: H6,
};

export type HeadingProps = ComponentProps<typeof NbHeading> & {
  children: string;
  level?: 1 | 2 | 3 | 4 | 5 | 6;
};

export function Heading({ children, level, ...props }: HeadingProps) {
  const theme = useTheme();

  if (Platform.OS === "web" && level !== undefined) {
    const WebHeading = Factory(headingMap[level], {
      ...theme.components.Heading,
      baseStyle: () => ({
        ...theme.components.Heading.baseStyle(),
        margin: 0,
      }),
    });

    return <WebHeading {...props}>{children}</WebHeading>;
  }

  return <NbHeading {...props}>{children}</NbHeading>;
}

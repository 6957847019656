import type { ICurrencyRecord } from "@cannabox/api-types";

export const formatCurrency = (
  currency: ICurrencyRecord,
  format: "long" | "short" = "long",
) => {
  if (format === "short") {
    return new Intl.NumberFormat("th-TH", {
      style: "currency",
      currency: currency.currencyCode,
      maximumFractionDigits: 0,
    }).format(currency.currencyValue / 100);
  }

  return `${Intl.NumberFormat("th-TH", {
    maximumFractionDigits: 0,
  }).format(currency.currencyValue / 100)} ${currency.currencyCode}`;
};

import { HStack, ScrollView } from "native-base";

import type { IStoreInventoryItemRecord } from "@cannabox/api-types";

import { useRootNavigation } from "~root/navigation";
import InventoryItemCard from "./InventoryItemCard";

export interface HScrollInventoryItem extends IStoreInventoryItemRecord {
  city: string;
  storeUrlSlug: string;
}

export interface HScrollInventoryItemsProps {
  inventoryItems: HScrollInventoryItem[];
}

export default function HScrollInventoryItems({
  inventoryItems,
}: HScrollInventoryItemsProps) {
  const { navigate } = useRootNavigation();

  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      <HStack space="6" pt="4" pb="6" px="4">
        {inventoryItems.map((inventoryItem) => (
          <InventoryItemCard
            key={inventoryItem.urlSlug}
            inventoryItem={inventoryItem}
            onPress={() => {
              navigate("Product", {
                city: inventoryItem.city,
                urlSlug: inventoryItem.storeUrlSlug,
                category: inventoryItem.category,
                productUrlSlug: inventoryItem.urlSlug,
              });
            }}
          />
        ))}
      </HStack>
    </ScrollView>
  );
}

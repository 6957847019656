import { useMemo } from "react";
import { type IStackProps } from "native-base";

import type { ProductBadgesProps } from "./ProductBadges";
import { type ITinctureDataRecord } from "~api/types";
import { supportedTincturesCategories } from "~root/config";
import { type cannaboxTheme } from "~root/services/cannabox-theme";
import ProductBadges from "./ProductBadges";

export interface TinctureBadgesProps {
  tinctureData: ITinctureDataRecord;
  lines?: number;
  shadow?: keyof typeof cannaboxTheme.shadows;
  stack?: IStackProps;
}

export default function TinctureBadges({
  tinctureData,
  shadow,
  lines,
  stack,
}: TinctureBadgesProps) {
  const productBadges = useMemo(() => {
    const badges: ProductBadgesProps["productBadges"] = [
      {
        colorScheme: "thc",
        children: `${tinctureData.thcMgPerDrop}mg THC / drop`,
      },
      {
        colorScheme: "cbd",
        children: `${tinctureData.cbdMgPerDrop}mg CBD / drop`,
      },
      {
        colorScheme: tinctureData.category,
        children: supportedTincturesCategories[tinctureData.category],
      },
      {
        colorScheme: "pcs",
        children: `${tinctureData.ml} ML`,
      },
    ];

    return badges;
  }, [tinctureData]);

  return (
    <ProductBadges
      lines={lines}
      stack={stack}
      shadow={shadow}
      productBadges={productBadges}
    />
  );
}

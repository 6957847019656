import loadable from "@loadable/component";

import { StoresList, StoresSeo } from "./components";
import { useStoresListViewParams } from "./hooks";

const GoToCartFooterActions = loadable(
  async () =>
    await import(
      /* webpackChunkName: "GoToCartFooterActions" */
      "~root/services/cart/components/GoToCartFooterActions/GoToCartFooterActions"
    ),
);

const RegistrationQuizSheet = loadable(
  async () =>
    await import(
      /* webpackChunkName: "RegistrationQuizSheet" */
      "~root/services/auth/components/RegistrationQuizSheet/RegistrationQuizSheet"
    ),
);

export default function StoresListView() {
  const { city } = useStoresListViewParams();

  return (
    <>
      <StoresSeo city={city} />

      <StoresList />

      <GoToCartFooterActions />

      <RegistrationQuizSheet />
    </>
  );
}

import { useCallback, useMemo } from "react";

import type { IGetManyStoresRecord } from "@cannabox/api-types";

import { Heading } from "~root/components/base";
import { RecommendedProducts, StoreListItem } from "./components";

type FlatListItem =
  | {
      type: "title";
      title: string;
    }
  | {
      type: "store";
      store: IGetManyStoresRecord;
    }
  | {
      type: "recommended-products";
    };

export const useStoresListData = (records: IGetManyStoresRecord[]) =>
  useMemo(() => {
    const items: FlatListItem[] = [
      {
        type: "recommended-products",
      },
    ];

    if (records.length > 0) {
      if (records[0].inventoryCount > 0) {
        items.push({
          type: "title",
          title: "Offering weed delivery under an hour",
        });

        items.push(
          ...records
            .filter((record) => record.inventoryCount > 0)
            .map(
              (record) =>
                ({
                  type: "store",
                  store: record,
                }) satisfies FlatListItem,
            ),
        );
      }

      items.push({
        type: "title",
        title: "Stores without offers",
      });

      items.push(
        ...records
          .filter((record) => record.inventoryCount === 0)
          .map(
            (record) =>
              ({
                type: "store",
                store: record,
              }) satisfies FlatListItem,
          ),
      );
    }

    return items;
  }, [records]);

export const useKeyExtractor = () => (item: FlatListItem) => {
  switch (item.type) {
    case "title":
      return item.title;
    case "store":
      return item.store.id.toString();
    case "recommended-products":
      return "recommended-products";
  }
};

export const useRenderItem = (city?: string) =>
  useCallback(({ item }: { item: FlatListItem }) => {
    switch (item.type) {
      case "title":
        return (
          <Heading level={2} fontSize="lg" px="1" pt="4" pb="2">
            {item.title}
          </Heading>
        );
      case "store":
        return <StoreListItem store={item.store} />;
      case "recommended-products":
        return <RecommendedProducts city={city} />;
    }
  }, []);

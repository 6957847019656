export interface SeoBreadcrumb {
  title: string;
  url: string;
}

export interface SeoBreadcrumbsProps {
  breadcrumbs: SeoBreadcrumb[];
}

export const createBreadcrumb = (
  breadcrumb: SeoBreadcrumb,
  position: number,
) => ({
  "@type": "ListItem",
  name: breadcrumb.title,
  item: breadcrumb.url,
  position,
});

export const createBreadcrumbs = (breadcrumbs: SeoBreadcrumb[]) => ({
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: breadcrumbs.map((breadcrumb, i) =>
    createBreadcrumb(breadcrumb, i + 1),
  ),
});
